<template>
  <div>
    <a-row :gutter="24">
      <a-date-picker
        v-model="selectedDate"
        @change="dashboardReport"
        style="margin-bottom: 16px"
      ></a-date-picker>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <p style="font-size: 20px; font-family: Arial">
          <b>Event Wise User <a-spin v-if="confirmLoading" /></b>
        </p>
        <apexchart
          width="700"
          height="300"
          :options="chartOptions"
          :series="chartSeries"
          type="bar"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p style="font-size: 20px; font-family: Arial">
          <b>
            Schedule Notification(
            {{ notificationTitle }})
            <a-spin v-if="confirmLoading" />
          </b>
        </p>
        <apexchart
          width="500"
          height="300"
          :options="chartOptionsNoti"
          :series="chartSeriesNoti"
          type="bar"
        />
      </a-col>

      <a-col :span="12">
        <p style="font-size: 20px; font-family: Arial">
          <b
            >push notification( {{ notificationTitleWithPush }})
            <a-spin v-if="confirmLoading"
          /></b>
        </p>
        <apexchart
          width="500"
          height="300"
          :options="chartOptionsPushNoti"
          :series="chartSeriesPushNoti"
          type="bar"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { sendRequest } from "@/http/axios.method";
import { Table, DatePicker } from "ant-design-vue";

export default {
  components: {
    Apexchart: VueApexCharts,
    "a-table": Table,
    "a-date-picker": DatePicker,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columns: [
        { title: "Event Name", dataIndex: "event_name" },
        { title: "Count", dataIndex: "count" },
      ],
      dataSource: [],
      dataSourceNoti: [],
      dataSourcePushNoti: [],
      selectedDate: null,
      notificationTitle: "",
      notificationTitleWithPush: "",
      confirmLoading: false,
      chartOptions: {
        chart: {
          type: "bar",
        },
      
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
          },
        },
        yaxis: {
          min: 0,
          labels: {
            show: true,
          },
        },
      },
      chartOptionsNoti: {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
          },
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
      },
      chartOptionsPushNoti: {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
          },
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
      },
      chartSeries: [],
      chartSeriesNoti: [],
      chartSeriesPushNoti: [],
    };
  },
  mounted() {
    this.dashboardReport();

    //this.generateChart();
  },
  methods: {
    dashboardReport() {
      this.confirmLoading = true;
      const formattedDate = this.selectedDate
        ? this.selectedDate.format("YYYY-MM-DD")
        : "";

      let form_data = new FormData();
      form_data.append("formattedDate", formattedDate);
      // console.log(form_data);
      sendRequest("post", "admin/event-wise-user-daily", form_data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data;
            this.dataSource = data;

            this.generateChart(response.data);
            this.dashboardReportNoti();
            this.dashboardReportPushNoti();
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    dashboardReportNoti() {
      this.confirmLoading = true;
      const formattedDate = this.selectedDate
        ? this.selectedDate.format("YYYY-MM-DD")
        : "";

      let form_data = new FormData();
      form_data.append("formattedDate", formattedDate);
      // console.log(form_data);
      sendRequest(
        "post",
        "admin/get-event-wise-user-daily-notification",
        form_data,
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;

          if (response.success) {
            let data = response.data;
            if (data.length > 0) {
              this.notificationTitle = data[0]?.notification_title;
            } else {
              this.notificationTitle = "No data found";
            }
            this.dataSourceNoti = data;

            this.generateChartNoti(response.data);
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    dashboardReportPushNoti() {
      this.confirmLoading = true;
      const formattedDate = this.selectedDate
        ? this.selectedDate.format("YYYY-MM-DD")
        : "";

      let form_data = new FormData();
      form_data.append("formattedDate", formattedDate);
      // console.log(form_data);
      sendRequest(
        "post",
        "admin/get-event-wise-user-daily-push-notification",
        form_data,
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;

          if (response.success) {
            let data = response.data;
            if (data.length > 0) {
              this.notificationTitleWithPush = data[0]?.notification_title;
            } else {
              this.notificationTitleWithPush = "No data found";
            }
            this.dataSourcePushNoti = data;

            this.generateChartPushNoti(response.data);
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    generateChart(response) {
      const data = response;
      const tempCategory = [];
      const eventNames = data.map((record) =>
        // this.chartOptions.xaxis.categories.push(record.event_name)
        tempCategory.push(record.event_name)
      );

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: tempCategory,
        },
      };

      const counts = data.map((record) => record.count);

      this.chartSeries = [{ name: "Event Count", data: counts }];
    },
    generateChartNoti(response) {
      const data = response;

      const tempCategoryNoti = [];
      const eventNames = data.map((record) =>
        // this.chartOptions.xaxis.categories.push(record.event_name)
        tempCategoryNoti.push(record.event_name)
      );

      this.chartOptionsNoti = {
        ...this.chartOptionsNoti,
        xaxis: {
          ...this.chartOptionsNoti.xaxis,
          categories: tempCategoryNoti,
        },
      };

      const counts = data.map((record) => record.count);

      this.chartSeriesNoti = [{ name: "Event", data: counts }];
    },
    generateChartPushNoti(response) {
      const data = response;

      const tempCategoryNoti = [];
      const eventNames = data.map((record) =>
        // this.chartOptions.xaxis.categories.push(record.event_name)
        tempCategoryNoti.push(record.event_name)
      );

      this.chartOptionsPushNoti = {
        ...this.chartOptionsPushNoti,
        xaxis: {
          ...this.chartOptionsPushNoti.xaxis,
          categories: tempCategoryNoti,
        },
      };

      const counts = data.map((record) => record.count);

      this.chartSeriesPushNoti = [{ name: "Event", data: counts }];
    },
  },
};
</script>

<style>
/* Add any necessary custom styles here */
</style>
